.justify-center {
  justify-content: center;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  will-change: background-color;
  border-bottom: 1px solid #e0e0e0;

  &.isWhite {
    background: #fff;
  }

  .header--navlink {
    @extend .l-flex-center;
    font-size: 14px;
    line-height: 18px;
    padding: 0 35px;
    height: $height_header;
    color: #a8a8a8;
    &:hover,
    &.isActive {
      border-top: 2px transparent solid;
      border-bottom: 2px $color-highlight solid;
    }
    &.isActive {
      color: $color-highlight;
      font-weight: 900;
    }
  }

  .l-button-outline {
    text-transform: uppercase;
  }
}

.header-subtract {
  padding-top: $height_header;
  width: 100%;
  height: calc(100%);
}

@media #{$mobile} {
  &:hover,
  &.isActive {
    margin-bottom: 7px !important;
  }
}

@media #{$mobile} {
  .header-subtract {
    padding-top: $height_header_mob;
  }
}

.header--content {
  height: $height_header;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media #{$mobile} {
  .header--content {
    height: $height_header_mob;
  }
}

.header--logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}
